import React from 'react'

const FAQ = () => {
  return (
    <div style={{marginTop: '80px'}}>
       <div id="faqs-page" style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1666993077/blue-bitcoin-digital-currency-world-globe-hologram_384372-79.jpg)"}} className="page-hero-section division">
        <div className="page-hero-overlay division">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-8">
                <div className="hero-txt text-center white-color">
                  {/* Title */}
                  <h2 className="h2-xs text-white">Frequently Asked Questions</h2>
                  {/* Text */}
                  <p className="p-xl text-white">
                    Do you have any questions about
FXGLOBAL LIMITED    

 you can check from the list of questions below
                  </p>
                </div>
              </div>
            </div>{" "}
            {/* End row */}
          </div>{" "}
          {/* End container */}
        </div>{" "}
        {/* End hero-overlay */}
        {/* WAVE SHAPE BOTTOM */}
        <div className="wave-shape-bottom">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80">
            <path
              fill="#ffffff"
              fillOpacity={1}
              d="M0,32L120,42.7C240,53,480,75,720,74.7C960,75,1200,53,1320,42.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
            />
          </svg>
        </div>
      </div>{" "}
      {/* END PAGE HERO */}
      {/* FAQs-2
			============================================= */}
      <section id="faqs-2" className="wide-60 faqs-section division">
        <div className="container">
          {/* FAQs-2 QUESTIONS */}
          <div className="faqs-2-questions">
            <div className="row row-cols-1 row-cols-lg-2 gy-4">
              {/* QUESTIONS HOLDER */}
              <div className="col">
                <div className="questions-holder pr-15">
                  {/* QUESTION #1 */}
                  <div className="question wow fadeInUp">
                    {/* Question */}
                    <h5 className="h5-md text-dark" style={{fontWeight: '500px', marginTop: '40px'}}>
                      What exactly is 
FXGLOBAL LIMITED    

 all about ?
                    </h5>
                    {/* Answer */}
                    <p className="p-lg">
                     
FXGLOBAL LIMITED    

 is an investment comapny that uses pooled assets from investors to trade Forex, Crypto and stock markets and pay back profits to users daily depending on the trade performance for the day.
                    </p>
                  </div>
                  {/* QUESTION #2 */}
                  <div className="question wow fadeInUp">
                    {/* Question */}
                    <h5 className="h5-md text-dark" style={{fontWeight: '500px', marginTop: '40px'}}>
                      What are the requirements for buying shares from 
FXGLOBAL LIMITED    


                    </h5>
                    {/* Answer */}
                    <p className="p-lg">
                      There are no other special requirements to become a shareholder, simply register/create an account with us then purchase any of our shares. That's all; sit back and watch the magic happen.
                    </p>
                  </div>
                  {/* QUESTION #3 */}
                  <div className="question wow fadeInUp">
                    {/* Question */}
                    <h5 className="h5-md text-dark" style={{fontWeight: '500px', marginTop: '40px'}}>
                      How do 
FXGLOBAL LIMITED    

 make her profit
                    </h5>
                    {/* Answer */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">
                          
FXGLOBAL LIMITED    

 pools assets/resources from sharehlders which are used in trading Forex, cryptocurrency and other Defi projects. The profits are made from trading Forex and Cryptocurrency. 
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          Through the 
FXGLOBAL LIMITED    

 blockchain system we are sure of a boom in our Net Assets Value (NAV)
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>{" "}
              {/* END QUESTIONS HOLDER */}
              {/* QUESTIONS HOLDER */}
              <div className="col">
                <div className="questions-holder pl-15">
                  {/* QUESTION #4 */}
                  <div className="question wow fadeInUp">
                    {/* Question */}
                    <h5 className="h5-md text-dark" style={{fontWeight: '500px', marginTop: '40px'}}>What payment methods are accepted</h5>
                    {/* Answer */}
                    <p className="p-lg">
                      We currently accept cryptocurrency, for easy and swift transactions we prefer the cryptocurrency payment methods as we have a lot of Crypto assets to receive payment from.
                    </p>
                  </div>
                  {/* QUESTION #5 */}
                  <div className="question wow fadeInUp">
                    {/* Question */}
                    <h5 className="h5-md text-dark" style={{fontWeight: '500px', marginTop: '40px'}}>How does 
FXGLOBAL LIMITED    

 handle my privacy?</h5>
                    {/* Answer */}
                    <p className="p-lg">
                      All information regarding our users and their portfolios will never be disclsed to any one.
                    </p>
                    {/* Answer */}
                    <p className="p-lg">
                      If you feel like your privacy has been violated kindly contact the support for proper actions.
                    </p>
                  </div>
                  {/* QUESTION #6 */}
                  <div className="question wow fadeInUp">
                    {/* Question */}
                    <h5 className="h5-md text-dark" style={{fontWeight: '500px', marginTop: '40px'}}>I have an issue with my account</h5>
                    {/* Answer */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">
                          Kindly contact our support through any of our contact mediums.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">
                          You can send a mail to us <a href="mailto:support@jetpayglobal.com">here: support@jetpayglobal.com</a>
                        </p>
                      </li>
                    </ul>
                  </div>
        
                </div>
              </div>{" "}
              {/* END QUESTIONS HOLDER */}
            </div>{" "}
            {/* End row */}
          </div>{" "}
          {/* END FAQs-2 QUESTIONS */}
          {/* MORE QUESTIONS BUTTON */}
        </div>{" "}
        {/* End container */}
      </section>
      {/* END FAQs-2 */}
      {/* CALL TO ACTION-7
			============================================= */}
      <div id="cta-7" className="cta-section division">
        <div className="container">
          <div className="cta-7-wrapper bg-snow">
            <div className="row d-flex align-items-center">
              {/* TEXT */}
              <div className="col-lg-9">
                <div className="cta-7-txt">
                  <p className="p-xl">
                    {`Still have a question? Don't worry, our customer care team
                    is ready to help you with any questions or problems`}
                  </p>
                </div>
              </div>
              {/* BUTTON */}
              <div className="col-lg-3 text-end">
                <div className="cta-7-btn">
                    <a className="btn btn-skyblue tra-grey-hover" href='/contact'>Contact Us</a>
                </div>
              </div>
            </div>{" "}
            {/* End row */}
          </div>{" "}
          {/* End cta-7-wrapper */}
        </div>{" "}
        {/* End container */}
      </div>
    </div>
  )
}

export default FAQ
