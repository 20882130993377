import React from 'react'

const Affiliate = () => {
  return (
    <div>
      <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="assets/images/inner-page-banner-illustrations/affiliate.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Affiliate</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="{{url('/')}}">Home</a></li>
                <li className="breadcrumb-item"><a href="https://account.jetpayglobal.com/">Pages</a></li>
                <li className="breadcrumb-item">Affiliate</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* inner-page-banner-section end */}
  {/* affiliate-section start */}
  <section className="affiliate-section pt-120 pb-120">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-6">
          <div className="affiliate-content">
            <div className="section-header">
              <span className="section-subtitle">What You'll Get as</span>
              <h2 className="section-title">Affiliate Partner</h2>
              <p>Earn Unlimited Commissions with JETPAY GLOBAL Affiliate Program.Our partner program can increase your income by receiving percentage from the purchases made by your referrals into.</p>
            </div>
            <div className="affiliate-item wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
              <div className="icon">
                <div className="icon-inner">
                  <img src="assets/images/icons/investment/4.svg" alt="icon" />
                </div>
              </div>
              <div className="content">
                <h3 className="title">Attractive RevShare</h3>
                <p>We pay 24% on each purchase from your referrals.Our  average user makes purchases for $51.42 per month</p>
              </div>
            </div>{/* affiliate-item end */}
            <div className="affiliate-item wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
              <div className="icon">
                <div className="icon-inner">
                  <img src="assets/images/icons/investment/5.svg" alt="icon" />
                </div>
              </div>
              <div className="content">
                <h3 className="title">High Conversion Rate</h3>
                <p>Some of our affiliates have made about $70,000 as earnings by referring sales</p>
              </div>
            </div>{/* affiliate-item end */}
            <div className="affiliate-item wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
              <div className="icon">
                <div className="icon-inner">
                  <img src="assets/images/icons/investment/6.svg" alt="icon" />
                </div>
              </div>
              <div className="content">
                <h3 className="title">RealTime Statistics</h3>
                <p>Monitor your conversions on our affiliate dashboard</p>
              </div>
            </div>{/* affiliate-item end */}
          </div>
        </div>
        <div className="col-lg-5">
          <div className="affiliate-join-area">
            <div className="section-header text-center">
              <span className="section-subtitle">affiliate program</span>
              <h2 className="section-title">Join Now</h2>
              <p>Make Money By Joining JETPAY GLOBAL Affiliate</p>
            </div>
            <form className="affiliate-join-form">
              <div className="form-group">
                <input type="text" name="affiliate_name" id="affiliate_name" placeholder="Enter Your Name*" />
              </div>
              <div className="form-group">
                <input type="email" name="affiliate_email" id="affiliate_email" placeholder="Enter Your Email*" />
              </div>
              <div className="form-group">
                <input type="tel" name="affiliate_phone" id="affiliate_phone" placeholder="Enter Your Phone*" />
              </div>
              <div className="text-center mt-30">
                <button type="submit" className="btn btn-primary btn-hover text-small">submit now</button>
              </div>
            </form>
          </div>
          <div className="bonus-details-area">
            <h4 className="title">Bonus  Details</h4>
            <p>Turn your online presence into profit</p>
            <ul>
              <li>Monthly Bonus Plans</li>
              <li>Increased payouts</li>
              <li>Access to new EXCLUSIVE OFFERS</li>
              <li>Fast payouts</li>
            </ul>
            <a href="https://account.jetpayglobal.com/" className="read-more-btn">read more<i className="fa fa-long-arrow-right" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* affiliate-section end */}
  {/* part-section start */}
  <section className="part-section pt-120 pb-120">
    <div className="el-map"><img src="assets/images/elements/part-map.png" alt="image" /></div>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-header text-center">
            <span className="section-subtitle">You’re Part of something Big</span>
            <h2 className="header-amount">$50,257,285.47</h2>
            <p>Total Commissions Paid to Our Affiliates</p>
          </div>
        </div>
      </div>
      <div className="row align-items-end mb-none-30">
        <div className="col-lg-3 col-sm-6 mb-30">
          <div className="part-item">
            <span className="counter">20</span>
            <p>Supported Languages</p>
          </div>
        </div>{/* part-item end */}
        <div className="col-lg-3 col-sm-6 mb-30">
          <div className="part-item">
            <span className="counter">800</span>
            <span>K</span>
            <p>Popular Investors</p>
          </div>
        </div>{/* part-item end */}
        <div className="col-lg-3 col-sm-6 mb-30">
          <div className="part-item">
            <span className="counter">4.5</span>
            <span>M</span>
            <p>Users Worldwide</p>
          </div>
        </div>{/* part-item end */}
        <div className="col-lg-3 col-sm-6 mb-30">
          <div className="part-thumb">
            <img src="assets/images/elements/part-image.png" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* part-section end */}
  {/* affiliate-bonus-section start */}
  <section className="affiliate-bonus-section pt-120 pb-120">
    <div className="bg_img" data-background="assets/images/affiliate-bonus-bg.jpg" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-header text-center text-white">
            <span className="section-subtitle">Take a look at our latest</span>
            <h2 className="section-title">Affiliate Bonus</h2>
            <p>Our partners always get the best cost-effective transparent solutions and we are always ready to help on any issue. Great conversion rates, friendly online support, transparency and honesty are the reasons why we differ.</p>
          </div>
        </div>
      </div>
      <div className="row mb-none-30">
        <div className="col-lg-4 col-sm-6 mb-30">
          <div className="affiliate-bonus-slider-area">
            <div className="slider-header">
              <span>AFFILIATE ID</span>
              <span>BONUS</span>
            </div>
            <div className="affiliate-bonus-slider owl-carousel">
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
            </div>
          </div>{/* affiliate-bonus-slider-area end */}
        </div>
        <div className="col-lg-4 col-sm-6 mb-30">
          <div className="affiliate-bonus-slider-area">
            <div className="slider-header">
              <span>AFFILIATE ID</span>
              <span>BONUS</span>
            </div>
            <div className="affiliate-bonus-slider owl-carousel">
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
            </div>
          </div>{/* affiliate-bonus-slider-area end */}
        </div>
        <div className="col-lg-4 col-sm-6 mb-30">
          <div className="affiliate-bonus-slider-area">
            <div className="slider-header">
              <span>AFFILIATE ID</span>
              <span>BONUS</span>
            </div>
            <div className="affiliate-bonus-slider owl-carousel">
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
              <ul className="affiliate-bonus-list">
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
                <li>
                  <span>10****45</span>
                  <span>$3200.00</span>
                </li>
              </ul>
            </div>
          </div>{/* affiliate-bonus-slider-area end */}
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="affiliate-bonus-bottom text-center">
            <h3 className="title">Become an Affiliate</h3>
            <p>Get up to 40% Commission now</p>
            <a href="https://account.jetpayglobal.com/" className="btn btn-primary btn-hover text-small">join now!</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* affiliate-bonus-section end */}
  {/* memebership-level-section start */}
  <section className="memebership-level-section pt-120 pb-120">
    <div className="bg_img" data-background="assets/images/membership-level-bg.jpg" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-header text-center">
            <span className="section-subtitle">Referral commission and</span>
            <h2 className="section-title">Membership Level</h2>
            <p>Refferal Commmission and Membership Levels are of 3 levels as below</p>
          </div>
        </div>
      </div>
      <div className="row membership-item-area mb-none-30 justify-content-center">
        {/* <span class="area-badge">Referral <br>Commission</span> */}
        <div className="ribon"><img src="assets/images/elements/affiliate-ribon.png" alt /></div>
        <div className="col-lg-4 col-md-4 col-sm-6 mb-30">
          <div className="membership-item">
            <div className="icon"><img src="assets/images/icons/membership/1.png" alt="image" /></div>
            <span className="badge-status">newbie</span>
            <div className="level"><span>5%</span>1st Level</div>
          </div>
        </div>{/* membership-item end */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-30">
          <div className="membership-item">
            <div className="icon"><img src="assets/images/icons/membership/2.png" alt="image" /></div>
            <span className="badge-status">great</span>
            <div className="level"><span>10%</span>2nd Level</div>
          </div>
        </div>{/* membership-item end */}
        <div className="col-lg-4 col-md-4 col-sm-6 mb-30">
          <div className="membership-item">
            <div className="icon"><img src="assets/images/icons/membership/3.png" alt="image" /></div>
            <span className="badge-status">master</span>
            <div className="level"><span>25%</span>3rd Level</div>
          </div>
        </div>{/* membership-item end */}
      </div>
    </div>
  </section>
  {/* memebership-level-section end */}
  {/* how-work-section start */}
  <section className="how-work-section pt-120 pb-120">
    <div className="bg_img" data-background="assets/images/how-work-bg.png" />
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-header text-center text-white">
            <span className="section-subtitle">Here's How it Works</span>
            <h2 className="section-title">Getting Started?It's Simple</h2>
            <p>The affiliate program is our special feature for loyal Investors.Invite users and earn 40% of the fee on their exchange transactions!</p>
          </div>
        </div>
      </div>
      <div className="row mb-none-30">
        <div className="col-lg-4 col-md-6 mb-30">
          <div className="how-work-item">
            <div className="icon">
              <div className="circle-elements"><img src="assets/images/elements/how-work-icon-shape.png" alt="image" /></div>
              <div className="icon-inner"><img src="assets/images/icons/how-work/1.png" alt="image" /></div>
            </div>
            <h3 className="title">Join Program</h3>
            <p>Once you sign up, you’ll get a personalized link to send to your friends.Click Join Now below and start EARNING in just a few clicks.</p>
            <a href="https://account.jetpayglobal.com/" className="btn btn-primary">join now</a>
          </div>
        </div>{/* how-work-item end */}
        <div className="col-lg-4 col-md-6 mb-30">
          <div className="how-work-item">
            <div className="icon">
              <div className="circle-elements"><img src="assets/images/elements/how-work-icon-shape.png" alt="image" /></div>
              <div className="icon-inner"><img src="assets/images/icons/how-work/2.png" alt="image" /></div>
            </div>
            <h3 className="title">Promote</h3>
            <p>Share your referral link wherever, whenever (don’t be selfish).Monetize your traffic with a Tracking Link.</p>
            <a href="https://account.jetpayglobal.com/" className="btn btn-primary">Tracking Link</a>
          </div>
        </div>{/* how-work-item end */}
        <div className="col-lg-4 col-md-6 mb-30">
          <div className="how-work-item">
            <div className="icon">
              <div className="circle-elements"><img src="assets/images/elements/how-work-icon-shape.png" alt="image" /></div>
              <div className="icon-inner"><img src="assets/images/icons/how-work/3.png" alt="image" /></div>
            </div>
            <h3 className="title">Earn</h3>
            <p>Get up to 50% commission. Earn from ALL products with your 30 day cookie, not just those you advertise.</p>
            <a href="https://account.jetpayglobal.com/" className="btn btn-primary">Commission Model</a>
          </div>
        </div>{/* how-work-item end */}
      </div>
    </div>
  </section>
  {/* how-work-section end */}
  {/* testimonial-section start */}
  <section className="testimonial-section pt-120 pb-120">
    <div className="shape"><img src="assets/images/elements/testimonial-shape-2.png" alt="image" /></div>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-header text-center">
            <span className="section-subtitle">testimonials</span>
            <h2 className="section-title">Already Earning With Us</h2>
            <p>We pay 24% on each purchase from your referrals. Our average user makes purchases for $48.39 per month that means every month you can receive $11.61 from each of your referrals.</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-lg-6">
          <div className="testmonial-slider-wrapper style--two">
            <div className="testimonial-slider owl-carousel">
              <div className="testimonial-single-two">
                <div className="thumb"><img src="assets/images/testimonial/2.png" alt="image" /></div>
                <h4 className="name">Kevin Ohashi</h4>
                <span className="designation">Award winning blogger</span>
                <p>JETPAY GLOBAL has one of the friendliest affiliate programs.They’re definitely one of the bestinvestment website in the world. I’ve been using them for a long time and have never had any problems</p>
              </div>{/* testimonial-single-two end */}
              <div className="testimonial-single-two">
                <div className="thumb"><img src="assets/images/testimonial/3.png" alt="image" /></div>
                <h4 className="name">Kevin Ohashi</h4>
                <span className="designation">Award winning blogger</span>
                <p>JETPAY GLOBAL has one of the friendliest affiliate programs.They’re definitely one of the bestinvestment website in the world. I’ve been using them for a long time and have never had any problems</p>
              </div>{/* testimonial-single-two end */}
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="testimonial-shape-thumb d-lg-block d-none"><img src="assets/images/elements/testimonial-shape.png" alt="image" /></div>
        </div>
      </div>
    </div>
  </section>
</div>

    </div>
  )
}

export default Affiliate
