import React from 'react'

const WhyChooseUs = () => {
  return (
    <div className='container-fluid px-0' style={{backgroundColor: '#062019', backgroundImage: 'url(https://res.cloudinary.com/dvoynhghq/image/upload/v1715790348/imagereen_nzphma.png)'}}>
    <section  className="choose-us-section bg_img">

    <div className="container-fluid" style={{backgroundColor: 'rgba(3, 59, 12, 0.68)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
    
  <div className="container" >
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="section-header text-center pt-20">
          <span className="section-subtitle">Boost your Money</span>
          <h2 className="section-title text-white">Why Choose Us</h2>
          <p style={{color: '#def7f0'}}>Our service gives you better result and savings, as per your requirement and you can manage your investments from anywhere either form home or work place, any time.</p>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid p-0">
    <div className="row m-0">
      <div className="col-lg-12 p-0">
        <div className="choose-us-slider owl-carousel">
          <div className="choose-item text-center text-white" style={{backgroundColor: '#086047'}}>
            <div className="choose-thumb" >
              <img  src="assets/images/choose-us/1.png" alt="image" />
            </div>
            <div className="choose-content">
              <h3 className="title text-white">Fast Profit </h3>
              <p style={{color: '#e4f1ed'}}>We're talking about ways you can make money fast.Invest money and get reward, bonus and profit</p>
              <a href="https://dashboard.jetpayglobal.com" className="read-more-btn">get started<i className="fa fa-long-arrow-right" /></a>
            </div>
          </div>{/* choose-item end */}
          <div className="choose-item text-center text-white" style={{backgroundColor: '#086047'}}>
            <div className="choose-thumb">
              <img src="assets/images/choose-us/2.png" alt="image" />
            </div>
            <div className="choose-content">
              <h3 className="title text-white">Instant Withdraw</h3>
              <p style={{color: '#e4f1ed'}}>We’re extremely excited to launch instant withdrawals.you can deposit and withdraw funds in just a few clicks.</p>
              <a href="https://dashboard.jetpayglobal.com" className="read-more-btn">get started<i className="fa fa-long-arrow-right" /></a>
            </div>
          </div>{/* choose-item end */}
          <div className="choose-item text-center text-white" style={{backgroundColor: '#086047'}}>
            <div className="choose-thumb">
              <img src="assets/images/choose-us/3.png" alt="image" />
            </div>
            <div className="choose-content">
              <h3 className="title text-white">Dedicated Server</h3>
              <p style={{color: '#e4f1ed'}}>Dedicated server hosting with 100% guaranteed network uptime.There's no sharing of CPU time, RAM or bandwidth</p>
              <a href="https://dashboard.jetpayglobal.com" className="read-more-btn">get started<i className="fa fa-long-arrow-right" /></a>
            </div>
          </div>{/* choose-item end */}
          <div className="choose-item text-center text-white" style={{backgroundColor: '#086047'}}>
            <div className="choose-thumb">
              <img src="assets/images/choose-us/4.png" alt="image" />
            </div>
            <div className="choose-content">
              <h3 className="title text-white">DDoS Protection</h3>
              <p style={{color: '#e4f1ed'}}>To protect your resources from modern DDoS attacks is through a multi-layer deployment of purpose-built DDoS mitigation </p>
              <a href="https://dashboard.jetpayglobal.com" className="read-more-btn">get started<i className="fa fa-long-arrow-right" /></a>
            </div>
          </div>{/* choose-item end */}
          <div className="choose-item text-center text-white" style={{backgroundColor: '#086047'}}>
            <div className="choose-thumb">
              <img src="assets/images/choose-us/5.png" alt="image" />
            </div>
            <div className="choose-content">
              <h3 className=" text-white">24/7 Support</h3>
              <p style={{color: '#e4f1ed'}}>Our Technical Support team is available for any questions.Our  multilingual 24/7 support allows to keep in touch.</p>
              <a href="https://dashboard.jetpayglobal.com" className="read-more-btn">get started<i className="fa fa-long-arrow-right" /></a>
            </div>
          </div>{/* choose-item end */}
          <div className="choose-item text-center text-white" style={{backgroundColor: '#086047'}}>
            <div className="choose-thumb">
              <img src="assets/images/choose-us/1.png" alt="image" />
            </div>
            <div className="choose-content">
              <h3 className="title text-white">Fast Profit </h3>
              <p style={{color: '#e4f1ed'}}>We're talking about ways you can make money fast. Invest money and get reward, bonus and profit paid to your account instantly</p>
              <a href="https://dashboard.jetpayglobal.com" className="read-more-btn">get started<i className="fa fa-long-arrow-right" /></a>
            </div>
          </div>{/* choose-item end */}
        </div>
      </div>
    </div>
  </div>
 </div>
</section>
</div>


  )
}

export default WhyChooseUs
