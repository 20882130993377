import React from 'react'

const Banner2 = () => {
  return (
   <section style={{marginTop: '-130px'}} className="banner-section banner--five bg_img" data-background="https://res.cloudinary.com/dvoynhghq/image/upload/v1713780529/collage-finance-banner-concept_23-2150608802_xt6lch.jpg">
  <div className="container-fluid" style={{backgroundColor: 'rgba(3, 59, 12, 0.68)'}}>
    <div className='container'>
    <div className="row">
      <div className="col-lg-12">
        <div className="banner-content-area">
          <div id="particles-js" style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: -1}} />
          <div className="banner-content text-center">
            <h2 className="banner-title" style={{color: '#fff', fontWeight: 'bolder', paddingTop: '50px'}}>OUR MISSION IS YOUR FINANCIAL SUCCESS</h2>
            <a style={{backgroundColor: '#ff0000', color: '#ffffff', fontWeight: 'bolder'}} href="https://dashboard.jetpayglobal.com" className="btn btn-round">Get Started Now</a>
            <div className="banner-thumb anim-bounce">
              <img style={{height: '350px'}} src="https://res.cloudinary.com/dvoynhghq/image/upload/v1715754982/money-8760_256_htgkuw.gif" alt="image" />
              </div>
          </div>
        </div>
        <div className='' style={{transform: 'translate(0px, -180px)'}}>
          <p className='text-center text-white h2'>
          As a global investment firm, we foster diverse perspectives and embrace innovation to help our clients navigate the uncertainty of capital markets
          </p>
        </div>
      </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Banner2
